import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { keyframes } from '@emotion/react';

import s1 from '../images/s1.png';
import s2 from '../images/s2.png';
import s3 from '../images/s3.png';


const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const screenshots = [
  { src: s1, caption: 'Discover and join events' },
  { src: s2, caption: 'Chat with friends' },
  { src: s3, caption: 'Share your moments' },
];

const ScreenshotsSection = () => {
  return (
    <Container maxWidth="md">
      <Box my={5}>
        <Typography variant="h2" component="h2" gutterBottom>
          Screenshots
        </Typography>
        <Grid container spacing={3}>
          {screenshots.map((screenshot, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Box
                component="img"
                src={screenshot.src}
                alt={`Screenshot ${index + 1}`}
                width="100%"
                sx={{ animation: `${fadeInUp} 1s ease-in-out` }}
              />
              {/* <Typography variant="body2" component="p" sx={{ mt: 1 }}>
                {screenshot.caption}
              </Typography> */}
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default ScreenshotsSection;
