import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';

import profile from '../images/profile.png';
import plans from '../images/plans.png';
import matches from '../images/matches.png';
import community from '../images/community.png';
import hubspot from '../images/hubspot.png';
import business from '../images/business.png';

// Define features with titles, descriptions, and corresponding image paths
const features = [
  { title: 'Profile', description: 'Create and customize your profile.', image: profile},
  { title: 'Plans', description: 'Make plans and schedule events.', image: plans},
  { title: 'Matches', description: 'Find and connect with new people.', image: matches},
  { title: 'Community', description: 'Join communities and share interests.', image: community},
  { title: 'HubSpot', description: 'Manage your network and connections.', image: hubspot },
  { title: 'Business', description: 'Promote your business and services.', image:business},
];

const FeaturesSection = () => {
  return (
    <Container maxWidth="md">
      <Box my={5}>
        <Typography variant="h2" component="h2" align="center" gutterBottom>
          Features
        </Typography>
        <Grid container spacing={3}>
          {features.map((feature, index) => (
            <Grid item xs={12} md={6} key={index}>
              <Box textAlign="center" p={2}>
                <img
                  src={feature.image}
                  alt={feature.title}
                  style={{ width: '80%', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}
                />
                <Typography variant="h5" component="h3" gutterBottom>
                  {feature.title}
                </Typography>
                <Typography variant="body1">
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default FeaturesSection;
