import React from 'react';
import { CssBaseline, Box } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Header from './components/Header';
import AboutSection from './components/AboutSection';
import FeaturesSection from './components/FeaturesSection';
import ScreenshotsSection from './components/ScreenshotsSection';

import Footer from './components/Footer';
import { BrowserRouter as Router, } from 'react-router-dom';
// import { Helmet } from 'react-helmet-async';

const theme = createTheme({
  palette: {
    background: {
      default: '#000000',
    },
    text: {
      primary: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const App = () => {
  return (
    <>
     {/* <Helmet>
        <title>MyRL - Social Networking App</title>
      </Helmet> */}
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            bgcolor: 'background.default',
            color: 'text.primary',
            minHeight: '100vh', // Ensure content takes full height of viewport
          }}
        >
          <Header />

          {/* Main content sections */}
          {/* <Switch> */}
           
           
              <AboutSection />
              <FeaturesSection />
              <ScreenshotsSection />
              {/* Uncomment if you have TestimonialsSection */}
      
      
      

          {/* Footer component */}
          <Footer />

          {/* Navigation links (could be in Footer component or elsewhere) */}
          {/* <nav>
            <ul>
              <li>
                <Link to="/terms">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/privacy">Privacy Policy</Link>
              </li>
            </ul>
          </nav> */}
        </Box>
      </Router>
    </ThemeProvider>
    </>

  );
};

export default App;
