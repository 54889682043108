import React from 'react';
import { Typography, Box } from '@mui/material';
import { keyframes } from '@emotion/react';
// import headerBackground from '../images/back.png'; 

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const Header = () => {
    return (
        <Box
            sx={{
                position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        bgcolor: 'background.default',
        animation: `${fadeIn} 2s ease-in-out`,
        overflow: 'hidden', // Hide overflow to prevent content blurring
            }}
        >
           
           
            <Typography variant="h1" component="h1" gutterBottom>
                MyRL
            </Typography>
            <Typography variant="h5" component="p" gutterBottom  sx={{padding: '10px 20px'}}>
                Join the buzz of local happenings. 🎉 <br />
                Connect ,
                plan, match, and reward yourself with our
                all-in-one social app.
            </Typography>
            <Typography variant="h6" component="p" sx={{ mt: 2, bgcolor: 'rgba(255, 255, 255, 0.1)', padding: '10px 20px', borderRadius: '5px' }}>
                Coming Soon ⏳
            </Typography>
        </Box>
    );
};

export default Header;
