import React from 'react';
import { Typography, Box } from '@mui/material';
import { keyframes } from '@emotion/react';

// Define keyframes for animation
const hoverEffect = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
`;

const Footer = () => {
  return (
    <Box
      component="footer" // Use Box component as footer
      sx={{
        width: '100%', // Full width of the viewport
        bgcolor: '#ffffff', // White background color
        pt: 3, // Padding top
        pb: 2, // Padding bottom
        textAlign: 'center', // Center-align text
        color: '#333333', // Text color
        '& a': {
          color: '#333333',
          textDecoration: 'none',
          margin: '0 8px',
          animation: `${hoverEffect} 0.5s infinite`,
        },
      }}
    >
      <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
        &copy; 2024 MyRL. All rights reserved.
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mb: 1 }}>
        Contact us: <a href="mailto:connect@myrl.co">connect@myrl.co</a> 📧
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Follow us on social media: 
        {/* <a href="https://twitter.com/myrl">Twitter</a> | 
        <a href="https://facebook.com/myrl">Facebook</a> |  */}
        <a href="https://instagram.com/myrl.socials">Instagram</a> 📱
      </Typography>
    </Box>
  );
};

export default Footer;
