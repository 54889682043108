import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import home from '../images/home.png';
const AboutSection = () => {
  return (
    <Container maxWidth="md">
      <Box my={5} textAlign="center">
        <Typography variant="h2" component="h2" gutterBottom>
          About MyRL
        </Typography>
        <Typography variant="body1" component="p" gutterBottom>
          MyRL is your go-to social networking app to plan and meet with friends in real life. Enjoy the features you love from social apps, with an added twist of real-life meetups. Connect, share, and plan with ease. Whether you want to grab a coffee ☕, catch a movie 🎬, or just hang out, MyRL makes it simple and fun to organize and join events. Discover new places, meet new people, and make every moment memorable with MyRL. 😊 <br></br>
        </Typography>
        <br/>
        <Box
          component="img"
          src={home}
          alt="About MyRL"
          width="100%"
          borderRadius="8px"
        />
      </Box>
    </Container>
  );
};

export default AboutSection;
